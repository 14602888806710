import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { clearErrors } from '../actions/errorActions';
import { actualizarPreferencias, actualizarIDUnica } from '../actions/ts3Actions';

class Preferencias extends Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: null,
			usuario: '',
			password: '',
			old_usuario: '',
			old_password: '',
			id_unica: '',
			old_id_unica: ''
		};
	}

	componentDidMount() {
		const { usuario } = this.props.auth;
		this.setState({ usuario: usuario.usuario, password: usuario.clave, old_usuario: usuario.usuario, old_password: usuario.clave, id_unica: usuario.id_unica, old_id_unica: usuario.id_unica });
	}

	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			if (error.id === 'TS3_ERROR') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.ts3.usuario) {
			this.setState({ usuario: nextProps.ts3.usuario.usuario, password: nextProps.ts3.usuario.clave, old_usuario: nextProps.ts3.usuario.usuario, old_password: nextProps.ts3.usuario.clave, id_unica: nextProps.ts3.usuario.id_unica, old_id_unica: nextProps.ts3.usuario.id_unica });
		}
	}

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onSubmit = e => {
		e.preventDefault();
		const preferencias = {
			usuario: this.state.usuario,
			password: this.state.password,
		};
		this.props.clearErrors();
		this.props.actualizarPreferencias(preferencias, this.props.history);
	};

	cambiarIDTS3 = e => {
		e.preventDefault();
		this.props.clearErrors();
		window.$('#dialogo_cambiarid').modal({backdrop: 'static', keyboard: false});
		this.props.actualizarIDUnica(this.state.id_unica, this.props.history);
		setTimeout(() => { window.$('#dialogo_cambiarid').modal('hide'); }, 5000);
	};

	/* eslint-disable */
	render() {
		const { usuario } = this.props.auth;
		return (
			<div>
				<div className="page-header page-header-light">
					<div className="page-header-content header-elements-md-inline">
						<div className="page-title d-flex">
							<h4><i className="icon-home2 mr-2" /> <span className="font-weight-semibold">Preferencias</span> - ¡Configura tú cuenta!</h4>
							<a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
						</div>
					</div>

					<div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
						<div className="d-flex">
							<div className="breadcrumb">
								<Link to="/" className="breadcrumb-item"><i className="icon-home2 mr-2" />Inicio</Link>
								<span className="breadcrumb-item active">Preferencias</span>
							</div>
							<a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
						</div>
					</div>
				</div>

				<div className="content">
					<div className="card">
						<div className="card-header bg-dark text-white header-elements-inline">
							<h6 className="card-title"><span className="font-weight-semibold"><i className="icon-gear mr-2 icon-1x"></i> Preferencias básicas</span></h6>
						</div>
						<div className="card-body">
							<form className="form-horizontal" onSubmit={this.onSubmit} autoComplete="off">
								<fieldset className="mb-3">
									<legend className="text-uppercase font-size-sm font-weight-bold">Preferencias de usuario</legend>
									<div className="form-group row">
										<label className="col-form-label col-lg-2">ID Interna</label>
										<div className="col-lg-10">
											<input type="text" className="form-control" value={usuario.id} disabled />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-form-label col-lg-2">ID TS3</label>
										<div className="col-lg-10">
											<input type="text" className="form-control" value={usuario.id_unica} disabled />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-form-label col-lg-2">Usuario</label>
										<div className="col-lg-10">
											<input type="text" className="form-control" id="usuario" name="usuario" placeholder="Usuario para entrar al panel" onChange={this.onChange} value={this.state.usuario} required />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-form-label col-lg-2">Contraseña</label>
										<div className="col-lg-10">
											<input type="password" className="form-control" id="password" name="password" placeholder="Contraseña para entrar al panel" onChange={this.onChange} value={this.state.password} required />
										</div>
									</div>
								</fieldset>
								<div className="text-right">
									<button type="submit" className="btn btn-success" disabled={this.state.old_usuario == this.state.usuario && this.state.old_password == this.state.password}><i className="icon-paperplane mr-2"></i> Guardar cambios</button>
								</div>
							</form>
						</div>
					</div>

					<div className="card">
						<div className="card-header bg-dark text-white header-elements-inline">
							<h6 className="card-title"><span className="font-weight-semibold"><i className="icon-gear mr-2 icon-1x"></i> Cambiar ID Única TS3</span></h6>
						</div>
						<div className="card-body">
							<form className="form-horizontal" onSubmit={this.cambiarIDTS3} autoComplete="off">
								<fieldset className="mb-3">
									<div className="form-group row">
										<label className="col-form-label col-lg-2">Nueva ID única</label>
										<div className="col-lg-10">
											<input type="text" className="form-control" id="id_unica" name="id_unica" placeholder="ID única TS3" onChange={this.onChange} value={this.state.id_unica} required />
										</div>
									</div>
								</fieldset>
								<div className="text-right">
									<button type="submit" className="btn btn-success" disabled={this.state.old_id_unica == this.state.id_unica}><i className="icon-paperplane mr-2"></i> Confirmar ID única</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div id="dialogo_cambiarid" className="modal fade">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header bg-primary">
								<h5 className="modal-title">Cambio de ID única</h5>
							</div>
							<div className="modal-body">
								<p><strong>Un segundo, estoy intentando cambiarte la id única del TS3...</strong></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Preferencias.propTypes = {
	auth: PropTypes.object.isRequired,
	error: PropTypes.object.isRequired,
	clearErrors: PropTypes.func.isRequired,
	actualizarPreferencias: PropTypes.func.isRequired,
	actualizarIDUnica: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	ts3: state.ts3,
	error: state.error
});

export default connect(
	mapStateToProps,
	{ clearErrors, actualizarPreferencias, actualizarIDUnica }
)(Preferencias);
