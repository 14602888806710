import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import $ from 'jquery';

import { login, autoLogin } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			usuario: '',
			password: '',
			msg: null
		};
	}

	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			if (error.id === 'LOGIN_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onSubmit = e => {
		e.preventDefault();
		const nuevoUsuario = {
			usuario: this.state.usuario,
			password: this.state.password,
		};
		this.props.login(nuevoUsuario, this.props.history);
	};

	async autoLogin() {
		window.$('#dialogo_autologin').modal({ backdrop: 'static', keyboard: false });
		let ip = null;
		await $.ajax('https://api.ipify.org?format=json')
			.then(
				function success(response) {
					ip = response.ip;
				},
				function fail(data, status) {
					console.log('Request failed.  Returned status of', status);
				}
			);
		const params = {
			ip: ip
		}
		this.props.autoLogin(params, this.props.history);
		setTimeout(() => { window.$('#dialogo_autologin').modal('hide'); }, 1000);
	}

	/* eslint-disable */
	render() {
		if (this.props.isAuthenticated) {
			return <Redirect to="/" />;
		}
		return (
			<Fragment>
				<div className="content d-flex justify-content-center align-items-center">
					<form className="login-form" onSubmit={this.onSubmit} autoComplete="off">
						<div className="card mb-0">
							<div className="card-body">
								<div className="text-center mb-3">
									<i className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
									<h5 className="mb-0">Panel de TS3</h5>
									<span className="d-block text-muted">Inicio de sesión</span>
								</div>
								<div className="form-group text-center text-muted content-divider">
									<span className="px-2">Credenciales</span>
								</div>
								<div className="form-group form-group-feedback form-group-feedback-left">
									<input type="text" className="form-control" id="usuario" name="usuario" placeholder="Usuario" onChange={this.onChange} value={this.state.usuario} required />
									<div className="form-control-feedback">
										<i className="icon-user-check text-muted" />
									</div>
									{this.state.msg ? (
										<span className="form-text text-danger">
											<i className="icon-cancel-circle2 mr-2" />
											{this.state.msg}
										</span>
									) : null}
								</div>
								<div className="form-group form-group-feedback form-group-feedback-left">
									<input type="password" className="form-control" id="password" name="password" placeholder="Contraseña" onChange={this.onChange} value={this.state.password} required />
									<div className="form-control-feedback">
										<i className="icon-user-lock text-muted" />
									</div>
								</div>
								<div className="form-group">
									<button type="submit" className="btn bg-blue btn-block">
										Entrar <i className="icon-arrow-right14 ml-2" />
									</button>
									<button type="button" className="btn bg-blue-800 btn-block" onClick={() => { this.autoLogin() }}>
										<i className="icon-feed mr-2"></i> Auto-Login
									</button>
								</div>
								<div className="form-group text-center text-muted content-divider mt-20">
									<span className="px-2">¿No tienes cuenta?</span>
								</div>
								<div className="form-group">
									<Link to="/registro" className="btn btn-warning btn-block">
										Crear cuenta
            						</Link>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div id="dialogo_autologin" className="modal fade">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header bg-primary">
								<h5 className="modal-title">Auto-Login</h5>
							</div>
							<div className="modal-body">
								<p><strong>Un segundo, estoy intentando detectar si estás conectado al servidor para hacer el autologin...</strong></p>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

Login.propTypes = {
	isAuthenticated: PropTypes.bool,
	error: PropTypes.object.isRequired,
	login: PropTypes.func.isRequired,
	autoLogin: PropTypes.func.isRequired,
	clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
	auth: state.auth,
	error: state.error
});

export default connect(
	mapStateToProps,
	{ login, autoLogin, clearErrors }
)(Login);