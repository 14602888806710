import { GET_ERRORS, CLEAR_ERRORS } from './types';

import Noty from 'noty';

export const returnErrors = (msg, status, id = null) => {
	if (msg.modal !== 0)
		new Noty({
			theme: ' alert alert-danger alert-styled-left p-0',
			text: msg.msg,
			type: 'danger',
			progressBar: false,
			closeWith: ['button']
		}).show();
	return {
		type: GET_ERRORS,
		payload: { msg, status, id }
	};
};

export const clearErrors = () => {
	return {
		type: CLEAR_ERRORS
	};
};
