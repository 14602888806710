export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const AUTOREG_SUCCESS = 'AUTOREG_SUCCESS';
export const AUTOREG_FAIL = 'AUTOREG_FAIL';

export const TS3_LOADED = 'TS3_LOADED';
export const TS3_LOADING = 'TS3_LOADING';
export const TS3_ERROR = 'TS3_ERROR';
export const TS3_SUCCESS = 'TS3_SUCCESS';
export const TS3_SUCCESS_UPD_TOKEN = 'TS3_SUCCESS_UPD_TOKEN';

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
