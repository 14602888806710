import { USER_LOADED, USER_LOADING, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, REGISTER_SUCCESS, REGISTER_FAIL, AUTOREG_SUCCESS, AUTOREG_FAIL } from '../actions/types';

const initialState = {
	token: localStorage.getItem('token'),
	isAuthenticated: null,
	isLoading: false,
	usuario: null
};

export default function (state = initialState, action) {
	switch (action.type) {
		case USER_LOADING:
			return {
				...state,
				isLoading: true
			};
		case USER_LOADED:
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				usuario: action.payload
			};
		case AUTOREG_SUCCESS:
			return {
				...state,
				token: null,
				usuario: action.payload,
				isAuthenticated: false,
				isLoading: false
			};
		case REGISTER_SUCCESS:
			return {
				...state,
				token: null,
				usuario: action.payload,
				isAuthenticated: false,
				isLoading: false
			};
		case LOGIN_SUCCESS:
			localStorage.setItem('token', action.payload.token);
			return {
				...state,
				...action.payload,
				isAuthenticated: true,
				isLoading: false
			};
		case AUTH_ERROR:
		case LOGIN_FAIL:
		case LOGOUT_SUCCESS:
		case REGISTER_FAIL:
		case AUTOREG_FAIL:
			localStorage.removeItem('token');
			return {
				...state,
				token: null,
				usuario: null,
				isAuthenticated: false,
				isLoading: false
			};
		default:
			return state;
	}
}
