import React, { Component, Fragment } from 'react';
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import $ from 'jquery';

import { clearErrors } from '../../actions/errorActions';
import { registro, autoRegistro } from '../../actions/authActions';

class Registro extends Component {
	constructor() {
		super();
		this.state = {
			usuario: '',
			password: '',
			id_unica: '',
			auto_reg: false,
			msg: null
		};
	}

	async componentDidMount() {
		window.$('#dialogo_cargar_usuarios').modal({ backdrop: 'static', keyboard: false });
		let ip = null;
		await $.ajax('https://api.ipify.org?format=json')
			.then(
				function success(response) {
					ip = response.ip;
				},
				function fail(data, status) {
					console.log('Request failed.  Returned status of', status);
				}
			);
		const params = {
			ip: ip
		}
		this.props.autoRegistro(params);
	}

	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.auth.usuario) {
			if (nextProps.auth.usuario.id_unica) {
				this.setState({
					id_unica: nextProps.auth.usuario.id_unica, auto_reg: true
				});
			}
		}
		setTimeout(() => { window.$('#dialogo_cargar_usuarios').modal('hide'); }, 1000);
	}

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onSubmit = e => {
		e.preventDefault();
		const nuevoUsuario = {
			usuario: this.state.usuario,
			password: this.state.password,
			id_unica: this.state.id_unica
		};
		this.props.clearErrors();
		this.props.registro(nuevoUsuario, this.props.history);
	};

	/* eslint-disable */
	render() {
		if (this.props.isAuthenticated) {
			return <Redirect to="/" />;
		}
		return (
			<Fragment>
				<div className="content d-flex justify-content-center align-items-center">
					<form className="login-form" onSubmit={this.onSubmit} autoComplete="off">
						<div className="card mb-0">
							<div className="card-body">
								<div className="text-center mb-3">
									<i className="icon-plus3 icon-2x text-success border-success border-3 rounded-round p-3 mb-3 mt-1" />
									<h5 className="mb-0">Crear cuenta</h5>
									<span className="d-block text-muted">Todos los campos son requeridos</span>
								</div>
								<div className="form-group text-center text-muted content-divider">
									<span className="px-2">Credenciales</span>
								</div>
								<div className="form-group form-group-feedback form-group-feedback-left">
									<input type="text" className="form-control" id="usuario" name="usuario" placeholder="Usuario" onChange={this.onChange} value={this.state.usuario} required />
									<div className="form-control-feedback">
										<i className="icon-user-check text-muted" />
									</div>
									{this.state.msg ? (
										<span className="form-text text-danger">
											<i className="icon-cancel-circle2 mr-2" />
											{this.state.msg}
										</span>
									) : null}
								</div>
								<div className="form-group form-group-feedback form-group-feedback-left">
									<input type="password" className="form-control" id="password" name="password" placeholder="Contraseña" onChange={this.onChange} value={this.state.password} required />
									<div className="form-control-feedback">
										<i className="icon-user-lock text-muted" />
									</div>
								</div>
								<div className="form-group text-center text-muted content-divider">
									<span className="px-2">TeamSpeak</span>
								</div>
								<div className="form-group form-group-feedback form-group-feedback-left">
									<input type="text" className="form-control" id="id_unica" name="id_unica" placeholder="Unique ID" onChange={this.onChange} value={this.state.id_unica} disabled={this.state.auto_reg} required />
									<div className="form-control-feedback">
										<i className="icon-pin-alt text-muted" />
									</div>
									<span className="form-text text-primary">
										<i className="icon-cancel-circle2 position-left" />
										<a data-target="#modal_full" data-toggle="modal">
											¿Donde se encuentra el Unique ID?
										</a>
									</span>
								</div>
								<div className="form-group">
									<button type="submit" className="btn bg-teal-400 btn-block">
										Crear cuenta <i className="icon-circle-right2 ml-2" />
									</button>
								</div>
								<div className="form-group text-center text-muted content-divider mt-20">
									<span className="px-2">¿Ya tienes cuenta?</span>
								</div>
								<div className="form-group">
									<Link to="/login" className="btn btn-warning btn-block">
										Iniciar sesión
            						</Link>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div id="dialogo_cargar_usuarios" className="modal fade">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header bg-primary">
								<h5 className="modal-title">Auto-Registro</h5>
							</div>
							<div className="modal-body">
								<p><strong>Un segundo, estoy intentando detectar si estás conectado al servidor de voz para autocompletar el registro...</strong></p>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

Registro.propTypes = {
	isAuthenticated: PropTypes.bool,
	error: PropTypes.object.isRequired,
	registro: PropTypes.func.isRequired,
	autoRegistro: PropTypes.func.isRequired,
	clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
	auth: state.auth,
	error: state.error
});

export default connect(
	mapStateToProps,
	{ registro, autoRegistro, clearErrors }
)(Registro);
