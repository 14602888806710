import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

import Noty from 'noty';

import { loadUser } from './actions/authActions';
import RutaPrivada from './components/RutaPrivada';

import Navbar from './components/Navbar';
import LeftNavbar from './components/LeftNavbar';
import Footer from './components/Footer';
import Registro from './components/auth/Registro';
import Login from './components/auth/Login';
import Inicio from './components/Inicio';
import Canales from './components/Canales';
import Grupos from './components/Grupos';
import Preferencias from './components/Preferencias';

class App extends Component {
	componentDidMount() {
		store.dispatch(loadUser());
		Noty.overrideDefaults({
			theme: 'limitless',
			layout: 'topRight',
			type: 'alert',
			timeout: 2500
		});
	}

	/* eslint-disable */
	render() {
		return (
			<Provider store={store}>
				<Router>
					<Fragment>
						<Navbar />
						<div className="page-content">
							<LeftNavbar />
							<div className="content-wrapper">
								<Switch>
									<RutaPrivada exact path="/" component={Inicio} />
									<Route exact path="/login" component={Login} />
									<Route exact path="/registro" component={Registro} />
									<RutaPrivada exact path="/canales" component={Canales} />
									<RutaPrivada exact path="/grupos" component={Grupos} />
									<RutaPrivada exact path="/preferencias" component={Preferencias} />
								</Switch>
								<Footer />
							</div>
						</div>
					</Fragment>
				</Router>
			</Provider>
		);
	}
}

export default App;

