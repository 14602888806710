import { combineReducers } from 'redux';

import errorReducer from './errorReducer';
import authReducer from './authReducer';
import ts3Reducer from './ts3Reducer';

export default combineReducers({
	error: errorReducer,
	auth: authReducer,
	ts3: ts3Reducer
});
