import axios from 'axios';
import { returnErrors } from './errorActions';
import { TS3_LOADING, TS3_ERROR, TS3_SUCCESS, TS3_SUCCESS_UPD_TOKEN, LOGOUT_SUCCESS } from './types';
import { URL_API } from '../config.js';

import Noty from 'noty';

export const getInfo = () => (dispatch, getState) => {
	dispatch({ type: TS3_LOADING });
	// prettier-ignore
	axios.get(URL_API + '/ts3/info', null, tokenConfig(getState))
		.then(res => dispatch({
			type: TS3_SUCCESS,
			payload: res.data
		}))
		.catch(err => {
			//dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const actualizarPreferencias = (preferencias) => (dispatch, getState) => {
	const body = { preferencias };
	// prettier-ignore
	axios.post(URL_API + '/ts3/actualizarpreferencias', body, tokenConfig(getState))
		.then(res => {
			dispatch(returnSuccess(TS3_SUCCESS_UPD_TOKEN, res.data))
		})
		.catch(err => {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const actualizarIDUnica = (id_unica) => (dispatch, getState) => {
	const body = { id_unica };
	// prettier-ignore
	axios.post(URL_API + '/ts3/actualizaridunica', body, tokenConfig(getState))
		.then(res => {
			window.$('#dialogo_cambiarid').modal('hide');
			dispatch(returnSuccess(LOGOUT_SUCCESS, res.data))
		})
		.catch(err => {
			window.$('#dialogo_cambiarid').modal('hide');
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const actualizarBandera = (bandera) => (dispatch, getState) => {
	const body = { bandera };
	// prettier-ignore
	axios.post(URL_API + '/ts3/actualizarbandera', body, tokenConfig(getState))
		.then(res => dispatch(returnSuccess(TS3_SUCCESS, res.data)))
		.catch(err => {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const getGruposUsuario = () => (dispatch, getState) => {
	dispatch({ type: TS3_LOADING });
	// prettier-ignore
	axios.get(URL_API + '/ts3/gruposusuario', tokenConfig(getState))
		.then(res => dispatch({
			type: TS3_SUCCESS,
			payload: res.data
		}))
		.catch(err => {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const getGrupos = (tipo) => (dispatch, getState) => {
	const body = { tipo };
	dispatch({ type: TS3_LOADING });
	// prettier-ignore
	axios.post(URL_API + '/ts3/grupos', body, tokenConfig(getState))
		.then(res => dispatch({
			type: TS3_SUCCESS,
			payload: res.data
		}))
		.catch(err => {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const actualizarGrupos = (lista_grupos) => (dispatch, getState) => {
	const body = { lista_grupos };
	// prettier-ignore
	axios.post(URL_API + '/ts3/actualizargrupos', body, tokenConfig(getState))
		.then(res => dispatch(returnSuccess(TS3_SUCCESS, res.data)))
		.catch(err => {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const crearCanal = (canal) => (dispatch, getState) => {
	const body = { canal };
	// prettier-ignore
	axios.post(URL_API + '/ts3/crearcanal', body, tokenConfig(getState))
		.then(res => dispatch(returnSuccess(TS3_SUCCESS, res.data)))
		.catch(err => {
			dispatch(returnErrors(err.response.data, err.response.status, 'TS3_ERROR'));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const editarCanal = (canal) => (dispatch, getState) => {
	const body = { canal };
	// prettier-ignore
	axios.post(URL_API + '/ts3/editarcanal', body, tokenConfig(getState))
		.then(res => dispatch(returnSuccess(TS3_SUCCESS, res.data)))
		.catch(err => {
			dispatch(returnErrors(err.response.data, err.response.status, 'TS3_ERROR'));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const expulsarJugadorCanal = (jugador, id_canal) => (dispatch, getState) => {
	const body = { jugador, id_canal };
	// prettier-ignore
	axios.post(URL_API + '/ts3/expulsarjugadorcanal', body, tokenConfig(getState))
		.then(res => dispatch(returnSuccess(TS3_SUCCESS, res.data)))
		.catch(err => {
			dispatch(returnErrors(err.response.data, err.response.status, 'TS3_ERROR'));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const eliminarCanal = (id_canal) => (dispatch, getState) => {
	const body = { id_canal };
	// prettier-ignore
	axios.post(URL_API + '/ts3/eliminarcanal', body, tokenConfig(getState))
		.then(res => dispatch(returnSuccess(TS3_SUCCESS, res.data)))
		.catch(err => {
			dispatch(returnErrors(err.response.data, err.response.status, 'TS3_ERROR'));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const getCanales = () => (dispatch, getState) => {
	dispatch({ type: TS3_LOADING });
	// prettier-ignore
	axios.get(URL_API + '/ts3/getcanales', tokenConfig(getState))
		.then(res => dispatch({
			type: TS3_SUCCESS,
			payload: res.data
		}))
		.catch(err => {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: TS3_ERROR
			});
		});
};

export const tokenConfig = getState => {
	const token = getState().auth.token;
	const config = {
		headers: {}
	};
	if (token) {
		config.headers['Authorization'] = 'JWT ' + token;
	}
	return config;
};


export const returnSuccess = (type, payload) => {
	new Noty({
		theme: ' alert alert-success alert-styled-left p-0',
		text: payload.msg,
		type: 'success',
		progressBar: false,
		closeWith: ['button']
	}).show();
	return {
		type: type,
		payload: payload
	};
};