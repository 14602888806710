import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { URL_API } from '../config.js';

import $ from 'jquery';

import { clearErrors } from '../actions/errorActions';
import { crearCanal, getCanales, getGrupos, expulsarJugadorCanal, eliminarCanal, editarCanal } from '../actions/ts3Actions';

class Canales extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id_canal: 0,
			nombre: '',
			password: '',
			canal_padre: 0,
			lista_canales: [],
			selector_canales: [],
			icono: 0,
			icono_editar: '',
			grupos: [],
			max_canales: 1,
			count_canales: 0,
			msg: null
		};
	}

	componentDidMount() {
		this.props.getCanales();
		this.props.getGrupos(1);
		if (this.props.auth.usuario.vip === 1)
			this.setState({ max_canales: 3 });
		else if (this.props.auth.usuario.vip === 2)
			this.setState({ max_canales: 4 });
		else if (this.props.auth.usuario.vip === 3)
			this.setState({ max_canales: 5 });
		const self = this;
		$(function () {
			window.$('#canal_padre').select2({
				minimumResultsForSearch: Infinity
			}).on('change', function (event) {
				let valor = $(event.currentTarget).val();
				self.setState({ canal_padre: valor });
			});
			window.$('.icono').select2({
				minimumResultsForSearch: Infinity
			}).on('change', function (event) {
				let valor = $(event.currentTarget).val();
				self.setState({ icono: valor });
			});
			window.$('.iconoEditar').select2({
				minimumResultsForSearch: Infinity
			}).on('change', function (event) {
				let valor = $(event.currentTarget).val();
				self.setState({ icono: valor });
			});
		});
	}

	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			if (error.id === 'TS3_ERROR') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.ts3.ts3_info) {
			this.setState({
				lista_canales: nextProps.ts3.ts3_info.lista_canales,
				selector_canales: nextProps.ts3.ts3_info.selector_canales
			});
			if (nextProps.ts3.ts3_info.grupos)
				this.setState({
					grupos: nextProps.ts3.ts3_info.grupos
				});
			if (nextProps.ts3.ts3_info.lista_canales) {
				this.setState({ count_canales: 0 });
				nextProps.ts3.ts3_info.lista_canales.forEach(canal => {
					this.setState(prevState => {
						return { count_canales: prevState.count_canales + 1 }
					});
				});
			}
		}
	}

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onSubmit = e => {
		e.preventDefault();
		const nuevoCanal = {
			nombre: this.state.nombre,
			password: this.state.password,
			canal_padre: this.state.canal_padre,
			icono: this.state.icono
		};
		if (nuevoCanal.canal_padre === 0)
			nuevoCanal.canal_padre = 46;
		this.props.clearErrors();
		this.props.crearCanal(nuevoCanal, this.props.history);
		setTimeout(() => { this.props.getCanales(); }, 500);
		window.$('#dialogo_crear_canal').modal('toggle');
	};

	onSubmitEditar = e => {
		e.preventDefault();
		const editarCanal = {
			id_canal: this.state.id_canal,
			nombre: this.state.nombre,
			password: this.state.password,
			canal_padre: this.state.canal_padre,
			icono: this.state.icono
		};
		if (editarCanal.canal_padre === 0)
			editarCanal.canal_padre = 46;
		this.props.clearErrors();
		this.props.editarCanal(editarCanal, this.props.history);
		setTimeout(() => { this.props.getCanales(); }, 500);
		window.$('#dialogo_editar_canal').modal('toggle');
	};

	crearCanal() {
		this.setState({
			nombre: '',
			password: ''
		});
	}

	editarCanal(canal) {
		let icono = 0;
		if (canal.iconId)
			icono = 'icon_' + canal.iconId
		this.setState({
			id_canal: canal.cid,
			nombre: canal.name,
			canal_padre: canal.pid,
			icono: icono
		});
		if (canal.flagPassword) {
			this.setState({
				password: '-999999'
			});
		}
		setTimeout(() => {
			window.$('.iconoEditar').select2().val(this.state.icono);
			window.$('.iconoEditar').select2().val(this.state.icono);
		}, 100);
	}

	diagEliminarCanal(canal) {
		this.setState({
			nombre: canal.name,
			id_canal: canal.cid
		});
	}

	eliminarCanal = e => {
		e.preventDefault();
		this.props.eliminarCanal(this.state.id_canal);
		setTimeout(() => { this.props.getCanales(); }, 500);
		window.$('#dialogo_eliminar_canal').modal('toggle');
	}

	expulsarJugadorCanal(jugador, id_canal) {
		this.props.expulsarJugadorCanal(jugador, id_canal);
		setTimeout(() => { this.props.getCanales(); }, 500);
	}

	listaCanales() {
		/* eslint-disable */
		const { lista_canales } = this.state;
		let ult_canal = 0;
		let identado = 1;
		let html_canales = [];
		if (lista_canales) {
			lista_canales.map((canal, i) => {
				let tipo_canal = (canal.flagPassword ? 'channel_yellow_subscribed.svg' : 'channel_green_subscribed.svg');
				if (canal.pid === ult_canal) {
					identado++;
					html_canales.push(
						<li className="list-group-item list-group-item-action" key={canal.cid} id={canal.cid}>
							<span className={'font-weight-semibold ml-' + identado}><img src={URL_API + '/images/ts3/' + tipo_canal} className="mr-2" alt="" width="25" />{canal.name}</span>
							<span className="ml-auto">
								{canal.iconId ? <img src={URL_API + '/images/iconos_ts3/icon_' + canal.iconId} className="mr-1" alt="" /> : ''}
								<button type="button" className="ml-2 btn bg-orange btn-icon btn-sm" onClick={() => { this.editarCanal(canal) }} data-toggle="modal" data-target="#dialogo_editar_canal"><i className="icon-pencil"></i></button>
								<button type="button" className="ml-1 btn bg-danger btn-icon btn-sm" onClick={() => { this.diagEliminarCanal(canal) }} data-toggle="modal" data-target="#dialogo_eliminar_canal"><i className="icon-trash"></i></button>
							</span>
						</li>
					);
				} else {
					identado = 1;
					html_canales.push(
						<li className="list-group-item list-group-item-action" key={canal.cid} id={canal.cid}>
							<span className="font-weight-semibold"><img src={URL_API + '/images/ts3/' + tipo_canal} className="mr-2" alt="" width="25" />{canal.name}</span>
							<span className="ml-auto">
								{canal.iconId ? <img src={URL_API + '/images/iconos_ts3/icon_' + canal.iconId} className="mr-1" alt="" /> : ''}
								<button type="button" className="ml-2 btn bg-orange btn-icon btn-sm" onClick={() => { this.editarCanal(canal) }} data-toggle="modal" data-target="#dialogo_editar_canal"><i className="icon-pencil"></i></button>
								<button type="button" className="ml-1 btn bg-danger btn-icon btn-sm" onClick={() => { this.diagEliminarCanal(canal) }} data-toggle="modal" data-target="#dialogo_eliminar_canal"><i className="icon-trash"></i></button>
							</span>
						</li>
					);
				}
				let identado_cliente = identado + 1;
				canal.clientes.map((cliente) => {
					let tipo_cliente = (cliente.flagTalking ? 'player_on' : (cliente.away ? 'away' : (cliente.inputMuted ? 'input_muted' : (cliente.outputMuted ? 'output_muted' : 'player_off'))));
					let html_grupos = [];
					cliente.grupos.map((grupo, i) => {
						html_grupos.push(<img src={URL_API + '/images/iconos_ts3/' + grupo.icon} className="mr-1" alt="" key={i} />);
					});
					html_canales.push(<li className="list-group-item list-group-item-action" key={canal.cid + cliente.clid}><span className={'font-weight-semibold ml-' + identado_cliente}><img src={URL_API + '/images/ts3/' + tipo_cliente + '.svg'} className="mr-2" alt="" width="20" />{cliente.nickname}</span><span className="ml-auto">{html_grupos}</span><span className="ml-2"><button type="button" className="btn btn-danger btn-icon btn-sm" onClick={() => { this.expulsarJugadorCanal(cliente.databaseId, canal.cid) }}><i className="icon-user-cancel"></i></button></span></li>);
				});
				ult_canal = canal.cid;
			});
		}
		return html_canales;
	}

	/* eslint-disable */
	render() {
		const { usuario } = this.props.auth;
		const { lista_canales, selector_canales, count_canales, max_canales, grupos, icono } = this.state;

		return (
			<div>
				<div className="page-header page-header-light">
					<div className="page-header-content header-elements-md-inline">
						<div className="page-title d-flex">
							<h4><i className="icon-list-unordered mr-2" /> <span className="font-weight-semibold">Canales</span> - ¡Aquí tienes tus canales privados!</h4>
							<a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
						</div>
						<div className="header-elements d-none">
							<button role="button" className="btn bg-primary" data-toggle="modal" data-target="#dialogo_crear_canal" onClick={() => { this.crearCanal() }} disabled={count_canales >= max_canales ? true : false}><i className="icon-plus2 mr-1"></i> Crear canal</button>
						</div>
					</div>

					<div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
						<div className="d-flex">
							<div className="breadcrumb">
								<Link to="/" className="breadcrumb-item"><i className="icon-home2 mr-2" />Inicio</Link>
								<span className="breadcrumb-item active">Canales</span>
							</div>
							<a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
						</div>
					</div>
				</div>

				<div className="content" style={{minHeight: '700px'}}>
					{count_canales > 0 ?
						<div className="card">
							<div className="card-header bg-dark text-white header-elements-inline">
								<h6 className="card-title"><span className="font-weight-semibold"><i className="icon-list-unordered mr-2 icon-1x"></i> Listado de canales</span></h6>
								<div className="header-elements">
									<span className={"float-right badge " + (count_canales >= max_canales ? 'bg-danger' : 'bg-info')}>{count_canales} / {max_canales}</span>
								</div>
							</div>
							<ul className="list-group list-group-flush border-top" key="0">
								{this.listaCanales()}
							</ul>
						</div>
						:
						<div className="alert alert-info alert-styled-left">
							<span className="font-weight-semibold">¡Información!</span> Aún no has creado ningún canal privado.
						</div>
					}
				</div>

				<div id="dialogo_crear_canal" className="modal fade">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header bg-primary">
								<h5 className="modal-title">Crear nuevo canal</h5>
								<button type="button" className="close" data-dismiss="modal">×</button>
							</div>
							<form className="form-horizontal" onSubmit={this.onSubmit} autoComplete="off">
								<div className="modal-body">
									{this.state.msg ? (
										<div className="alert alert-danger alert-dismissible alert-styled-left border-top-0 border-bottom-0 border-right-0">
											<span className="font-weight-semibold">¡Error!</span> {this.state.msg}
										</div>
									) : null}
									<div className="form-group row">
										<label className="col-form-label col-sm-3">Canal padre</label>
										<div className="col-sm-9">
											<select className="form-control select" data-fouc="" aria-hidden="true" id="canal_padre" name="canal_padre" style={{ width: '100%' }}>
												<option value="46">Ninguno</option>
												{selector_canales ? selector_canales.map((canal) => (
													<option value={canal.id} key={canal.id}>{canal.text}</option>
												)) : null}
											</select>
										</div>
									</div>
									<div className="form-group row">
										<label className="col-form-label col-sm-3">Icono</label>
										<div className="col-sm-9">
											<select className="form-control select icono" data-fouc="" aria-hidden="true" id="icono" name="icono" style={{ width: '100%' }}>
												<option value="0">Ninguno</option>
												{grupos ? grupos.map((grupo) => (
													<option value={grupo.icono} key={grupo.id}>{grupo.nombre}</option>
												)) : null}
											</select>
										</div>
									</div>
									<div className="form-group row">
										<label className="col-form-label col-sm-3">Nombre del canal</label>
										<div className="col-sm-9">
											<input type="text" className="form-control" id="nombre" name="nombre" placeholder="Nombre del canal" onChange={this.onChange} value={this.state.nombre} required />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-form-label col-sm-3">Contraseña</label>
										<div className="col-sm-9">
											<input type="password" className="form-control" id="password" name="password" placeholder="Contraseña del canal" onChange={this.onChange} value={this.state.password} />
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-light" data-dismiss="modal">Cerrar</button>
									<button type="submit" className="btn bg-primary"><i className="icon-plus2 mr-1"></i> Crear canal</button>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div id="dialogo_editar_canal" className="modal fade">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header bg-primary">
								<h5 className="modal-title">Editar canal - {this.state.nombre}</h5>
								<button type="button" className="close" data-dismiss="modal">×</button>
							</div>
							<form className="form-horizontal" onSubmit={this.onSubmitEditar} autoComplete="off">
								<div className="modal-body">
									{this.state.msg ? (
										<div className="alert alert-danger alert-dismissible alert-styled-left border-top-0 border-bottom-0 border-right-0">
											<span className="font-weight-semibold">¡Error!</span> {this.state.msg}
										</div>
									) : null}
									<div className="form-group row">
										<label className="col-form-label col-sm-3">Icono</label>
										<div className="col-sm-9">
											<select className="form-control select iconoEditar" data-fouc="" aria-hidden="true" id="icono" name="icono" style={{ width: '100%' }}>
												<option value="0">Ninguno</option>
												{grupos ? grupos.map((grupo) => (
													<option value={grupo.icono} key={grupo.id}>{grupo.nombre}</option>
												)) : null}
											</select>
										</div>
									</div>
									<div className="form-group row">
										<label className="col-form-label col-sm-3">Nombre del canal</label>
										<div className="col-sm-9">
											<input type="text" className="form-control" id="nombre" name="nombre" placeholder="Nombre del canal" onChange={this.onChange} value={this.state.nombre} required />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-form-label col-sm-3">Contraseña</label>
										<div className="col-sm-9">
											<input type="password" className="form-control" id="password" name="password" placeholder="Contraseña del canal" onChange={this.onChange} value={this.state.password} />
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-light" data-dismiss="modal">Cerrar</button>
									<button type="submit" className="btn bg-success"><i className="icon-floppy-disk mr-2"></i> Guardar cambios</button>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div id="dialogo_eliminar_canal" className="modal fade">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header bg-danger">
								<h5 className="modal-title">¡Atención! - Esta acción no es reversible</h5>
								<button type="button" className="close" data-dismiss="modal">×</button>
							</div>
							<form className="form-horizontal" onSubmit={this.eliminarCanal} autoComplete="off">
								<div className="modal-body">
									<p><strong>¿Estás seguro que quieres eliminar el canal {this.state.nombre} y todos los subcanales que dependen del mismo?</strong></p>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-light" data-dismiss="modal">Cerrar</button>
									<button type="submit" className="btn bg-danger"><i className="icon-trash" onClick={() => { this.eliminarCanal() }}></i> Eliminar canal</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Canales.propTypes = {
	auth: PropTypes.object.isRequired,
	error: PropTypes.object.isRequired,
	clearErrors: PropTypes.func.isRequired,
	crearCanal: PropTypes.func.isRequired,
	getCanales: PropTypes.func.isRequired,
	expulsarJugadorCanal: PropTypes.func.isRequired,
	getGrupos: PropTypes.func.isRequired,
	eliminarCanal: PropTypes.func.isRequired,
	editarCanal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
	ts3: state.ts3,
	error: state.error
});

export default connect(
	mapStateToProps,
	{ clearErrors, crearCanal, getCanales, getGrupos, expulsarJugadorCanal, eliminarCanal, editarCanal }
)(Canales);
