import axios from 'axios';
import { returnErrors } from './errorActions';
import { USER_LOADED, USER_LOADING, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, REGISTER_SUCCESS, REGISTER_FAIL, AUTOREG_SUCCESS, AUTOREG_FAIL } from './types';
import { URL_API } from '../config.js';

import Noty from 'noty';

const headers = {
	headers: {
		'Content-Type': 'application/json'
	}
};

export const loadUser = () => (dispatch, getState) => {
	//Cargando usuario
	dispatch({ type: USER_LOADING });
	// prettier-ignore
	axios.post(URL_API + '/estadotoken', null, tokenConfig(getState))
		.then(res => dispatch({
			type: USER_LOADED,
			payload: res.data
		}))
		.catch(err => {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: AUTH_ERROR
			});
		});
};

export const registro = ({ usuario, password, id_unica }, props) => dispatch => {
	const body = JSON.stringify({ usuario, password, id_unica });
	// prettier-ignore
	axios.post(URL_API + '/registro', body, headers).then(res => {
		new Noty({
			theme: ' alert alert-success alert-styled-left p-0',
			text: 'Cuenta creada correctamente. Por favor, inicia sesión.',
			type: 'success',
			progressBar: false,
			closeWith: ['button']
		}).show();
		dispatch({
			type: REGISTER_SUCCESS,
			payload: res.data
		});
		props.push('/login');
	}).catch((err) => {
		dispatch(returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL'));
		dispatch({
			type: REGISTER_FAIL
		});
	});
};

export const autoRegistro = ({ip}) => async (dispatch) => {
	// prettier-ignore
	const body = JSON.stringify({ip});
	axios.post(URL_API + '/autoregistro', body, headers).then(res => {
		dispatch({
			type: AUTOREG_SUCCESS,
			payload: res.data
		});
	}).catch((err) => {
		dispatch(returnErrors(err.response.data, err.response.status, 'AUTOREG_FAIL'));
		dispatch({
			type: AUTOREG_FAIL
		});
	});
};

export const login = ({ usuario, password }) => dispatch => {
	const body = JSON.stringify({ usuario, password });
	// prettier-ignore
	axios.post(URL_API + '/login', body, headers).then(res => {
		dispatch({
			type: LOGIN_SUCCESS,
			payload: res.data
		});
	}).catch((err) => {
		dispatch(returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL'));
		dispatch({
			type: LOGIN_FAIL
		});
	});
};

export const autoLogin = ({ip}) => async (dispatch) => {
	// prettier-ignore
	const body = JSON.stringify({ip});
	axios.post(URL_API + '/autologin', body, headers).then(res => {
		window.$('#dialogo_autologin').modal('hide');
		dispatch({
			type: LOGIN_SUCCESS,
			payload: res.data
		});
	}).catch((err) => {
		console.log(err)
		dispatch(returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL'));
		dispatch({
			type: LOGIN_FAIL
		});
	});
};

export const logout = () => {
	return {
		type: LOGOUT_SUCCESS
	};
};

export const tokenConfig = getState => {
	const token = getState().auth.token;
	const config = {
		headers: {}
	};
	if (token) {
		config.headers['Authorization'] = 'JWT ' + token;
	}
	return config;
};
