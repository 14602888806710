import { TS3_LOADING, TS3_LOADED, TS3_ERROR, TS3_SUCCESS, TS3_SUCCESS_UPD_TOKEN } from '../actions/types';

const initialState = {
	//token: localStorage.getItem('token'),
	isLoading: false,
	ts3_info: null
};

export default function (state = initialState, action) {
	switch (action.type) {
		case TS3_LOADING:
			return {
				...state,
				isLoading: true
			};
		case TS3_LOADED:
			return {
				...state,
				isLoading: false,
				ts3_info: action.payload
			};
		case TS3_SUCCESS:
			return {
				...state,
				...action.payload,
				isLoading: false
			};
		case TS3_SUCCESS_UPD_TOKEN:
			localStorage.setItem('token', action.payload.token);
			return {
				...state,
				...action.payload,
				isLoading: false
			};
		case TS3_ERROR:
			return {
				...state,
				isLoading: false,
				ts3_info: null
			};
		default:
			return state;
	}
}
