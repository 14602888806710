import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { URL_API } from '../config.js';

import $ from 'jquery';

import { getGruposUsuario, actualizarGrupos, actualizarBandera } from '../actions/ts3Actions';

class Grupos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ts3_info: { grupos_juegos: [], bandera: 0 },
			max_juegos: 3,
			count_juegos: 0,
			bandera: 0,
			bandera_actual: 0,
			msg: null
		};
	}

	componentDidMount() {
		this.props.getGruposUsuario();
		if (this.props.auth.usuario.vip === 1)
			this.setState({ max_juegos: 5 });
		const self = this;
		$(function () {
			setTimeout(function () {
				window.$('.select').select2({
					minimumResultsForSearch: Infinity
				}).on('change', function (event) {
					let valor = $(event.currentTarget).val();
					self.setState({ bandera: valor });
				});
			}, 500);
		});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.ts3.ts3_info) {
			this.setState({
				ts3_info: nextProps.ts3.ts3_info,
				bandera: nextProps.ts3.ts3_info.bandera,
				bandera_actual: nextProps.ts3.ts3_info.bandera
			});
			if (nextProps.ts3.ts3_info.grupos_juegos) {
				this.setState({ count_juegos: 0 });
				nextProps.ts3.ts3_info.grupos_juegos.forEach(juego => {
					if (juego.estado_actual) {
						this.setState(prevState => {
							return { count_juegos: prevState.count_juegos + 1 }
						});
					}
				});
			}
		}
	}

	handleChange = (e) => {
		/* eslint-disable */
		let lista_juegos = this.state.ts3_info.grupos_juegos;
		lista_juegos.forEach(juego => {
			if (juego.id == e.target.name) {
				juego.estado_actual = e.target.checked
				if (juego.estado_actual) {
					this.setState(prevState => {
						return { count_juegos: prevState.count_juegos + 1 }
					});
				} else {
					this.setState(prevState => {
						return { count_juegos: prevState.count_juegos - 1 }
					});
				}
			}
		});
		this.setState({ ts3_info: { grupos_juegos: lista_juegos } });
	}

	onSubmit = e => {
		e.preventDefault();
		let { bandera, bandera_actual } = this.state;
		let lista_juegos = this.state.ts3_info.grupos_juegos;
		let lista_juegos_cambios = [];
		lista_juegos.forEach(juego => {
			if (juego.estado_actual !== juego.estado_anterior) {
				let eliminar = (juego.estado_actual ? false : true);
				lista_juegos_cambios.push({ id: juego.id, eliminar: eliminar });
				juego.estado_anterior = juego.estado_actual;
			}
		});
		this.props.actualizarGrupos(lista_juegos_cambios);
		if (bandera != bandera_actual)
			this.props.actualizarBandera(bandera);
		this.setState({ ts3_info: { grupos_juegos: lista_juegos } });
	};

	/* eslint-disable */
	render() {
		const { usuario } = this.props.auth;
		const { count_juegos, max_juegos } = this.state;
		const { grupos_juegos, bandera } = this.state.ts3_info;
		return (
			<div>
				<div className="page-header page-header-light">
					<div className="page-header-content header-elements-md-inline">
						<div className="page-title d-flex">
							<h4><i className="icon-make-group mr-2" /> <span className="font-weight-semibold">Grupos</span> - ¡Asignate los grupos que quieras, pero dentro del límite!</h4>
							<a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
						</div>
					</div>

					<div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
						<div className="d-flex">
							<div className="breadcrumb">
								<Link to="/" className="breadcrumb-item"><i className="icon-home2 mr-2" />Inicio</Link>
								<span className="breadcrumb-item active">Grupos</span>
							</div>
							<a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
						</div>
					</div>
				</div>

				<div className="content">
					{(this.props.ts3.isLoading == false ?
						<div className="card">
							<div className="card-header bg-dark text-white header-elements-inline">
								<h6 className="card-title">
									<span className="font-weight-semibold"><i className="icon-make-group mr-2"></i> Asignador de grupos</span>
								</h6>
							</div>
							<div className="card-body">
								<form onSubmit={this.onSubmit} >
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<fieldset className="mb-3">
													<legend className="text-uppercase font-size-sm font-weight-bold">Lista de juegos disponibles <span className={"float-right badge " + (count_juegos >= max_juegos ? 'bg-danger' : 'bg-info')}>{count_juegos} / {max_juegos}</span></legend>
													{grupos_juegos.map((grupo) => (
														<div className="custom-control custom-control-right custom-checkbox" key={grupo.id}>
															<input type="checkbox" className="custom-control-input" id={grupo.id} name={grupo.id} checked={(grupo.estado_actual ? true : false)} onChange={this.handleChange} disabled={!grupo.estado_actual && count_juegos >= max_juegos ? true : false} />
															<label className="custom-control-label position-static" htmlFor={grupo.id}>
																{grupo.icono ? <img src={URL_API + '/images/iconos_ts3/' + grupo.icono} className="mr-2" /> : null}
																{grupo.nombre} {grupo.estado_actual}
															</label>
														</div>
													))}
												</fieldset>
											</div>
										</div>
										<div className="col-md-6">
											<fieldset className="mb-3">
												<legend className="text-uppercase font-size-sm font-weight-bold">Otros grupos</legend>
												<div className="form-group row">
													<label className="col-lg-3 col-form-label">Bandera:</label>
													<div className="col-lg-9">
														<select className="form-control select" data-fouc="" aria-hidden="true" id="bandera" name="bandera" placeholder="¿Qué bandera quieres mostrar?" style={{ width: '100%' }} defaultValue={bandera}>
															<option value="0">Sin asignar</option>
															<option value="78">ESPAÑA: Andalucia</option>
															<option value="79">ESPAÑA: Aragón</option>
															<option value="80">ESPAÑA: Asturias</option>
															<option value="81">ESPAÑA: Baleares</option>
															<option value="83">ESPAÑA: Cantabria</option>
															<option value="84">ESPAÑA: Canarias</option>
															<option value="85">ESPAÑA: Castilla-La Mancha</option>
															<option value="86">ESPAÑA: Cataluña</option>
															<option value="87">ESPAÑA: Ceuta</option>
															<option value="88">ESPAÑA: Pais Vasco</option>
															<option value="89">ESPAÑA: Extremadura</option>
															<option value="90">ESPAÑA: Galicia</option>
															<option value="91">ESPAÑA: Castilla-León</option>
															<option value="92">ESPAÑA: Madrid</option>
															<option value="93">ESPAÑA: Melilla</option>
															<option value="94">ESPAÑA: Murcia</option>
															<option value="95">ESPAÑA: Navarra</option>
															<option value="96">ESPAÑA: La Rioja</option>
															<option value="97">ESPAÑA: C. Valenciana</option>
															<option value="98">EXTRANJERO: Andorra</option>
															<option value="99">EXTRANJERO: Otros</option>
															<option value="100">EXTRANJERO: Argentina</option>
															<option value="101">EXTRANJERO: Colombia</option>
															<option value="102">EXTRANJERO: Ecuador</option>
															<option value="103">EXTRANJERO: México</option>
															<option value="104">EXTRANJERO: Perú</option>
															<option value="105">EXTRANJERO: Uruguay</option>
															<option value="106">EXTRANJERO: Venezuela</option>
														</select>
													</div>
												</div>
											</fieldset>
										</div>
										<div className="col-md-12">
											<legend className="text-uppercase font-size-sm font-weight-bold"></legend>
											<div className="text-right">
												<button type="submit" className="btn btn-primary">Guardar cambios <i className="icon-floppy-disk ml-2"></i></button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						: ''
					)}
				</div>
			</div>
		);
	}
}

Grupos.propTypes = {
	auth: PropTypes.object.isRequired,
	getGruposUsuario: PropTypes.func.isRequired,
	actualizarGrupos: PropTypes.func.isRequired,
	actualizarBandera: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	ts3: state.ts3
});

export default connect(
	mapStateToProps,
	{ getGruposUsuario, actualizarGrupos, actualizarBandera }
)(Grupos);
