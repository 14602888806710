import React, { Component } from 'react';

class Footer extends Component {
	/* eslint-disable */
	render() {
		return (
			<div className="navbar navbar-expand-lg navbar-light">
				<div className="text-center d-lg-none w-100">
					<button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
						<i className="icon-unfold mr-2" />
						Footer
					</button>
				</div>
				<div className="navbar-collapse collapse" id="navbar-footer">
					<span className="navbar-text">
						&copy; 2019. Algunos derechos reservados by{' '}
						<a href="https://steamcommunity.com/id/p3tt3r_19" target="_blank">
							peTTer
						</a>
					</span>
				</div>
			</div>
		);
	}
}

export default Footer;
