import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getInfo } from '../actions/ts3Actions';

class Inicio extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ts3_info: { virtualserver_clientsonline: 0 },
			msg: null
		};
	}

	componentDidMount() {
		this.props.getInfo();
		this.interval = setInterval(() => this.props.getInfo(), 30000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.ts3.ts3_info) {
			this.setState({
				ts3_info: nextProps.ts3.ts3_info
			});
		}
	}

	/* eslint-disable */
	render() {
		const { usuario } = this.props.auth;
		const { ts3_info } = this.state;
		return (
			<div>
				<div className="page-header page-header-light">
					<div className="page-header-content header-elements-md-inline">
						<div className="page-title d-flex">
							<h4><i className="icon-home2 mr-2" /> <span className="font-weight-semibold">Inicio</span> - Panel de entrada</h4>
							<a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
						</div>
					</div>

					<div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
						<div className="d-flex">
							<div className="breadcrumb">
								<span className="breadcrumb-item active"><i className="icon-home2 mr-2" />Inicio</span>
							</div>
							<a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
						</div>
					</div>
				</div>

				<div className="content">
					<div className="row">
						<div className="col-md-8" />
						<div className="col-md-4">
							<div className="card">
								<div className="card-header bg-info text-white header-elements-inline">
									<h4 className="card-title">
										<span className="font-weight-semibold"><i className="icon-user mr-2 icon-2x"></i> {usuario.nombre}</span>
									</h4>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<p><strong>ID:</strong></p>
											<p><strong>ID única:</strong></p>
											<p><strong>Total de conexiones al servidor:</strong></p>
											<p><strong>Fecha inicio estadisticas:</strong></p>
											<p><strong>Fecha última conexión:</strong></p>
											<p><strong>Tiempo total online:</strong></p>
											<p><strong>Tipo de cuenta:</strong></p>
											<p><strong>Bandera:</strong></p>
										</div>
										<div className="col-md-6">
											<p className="text-right">{usuario.id}</p>
											<p className="text-right">{usuario.id_unica}</p>
											<p className="text-right">{usuario.num_con}</p>
											<p className="text-right">{moment(usuario.fecha_creacion, 'yyyy/mm/dd HH:mm:ss').format('DD/MM/YYYY HH:MM:SS')}</p>
											<p className="text-right">{moment(usuario.fecha_ult_con, 'yyyy/mm/dd HH:mm:ss').format('DD/MM/YYYY HH:MM:SS')}</p>
											<p className="text-right">{moment('2000-01-01 00:00:00').add(moment.duration(usuario.tiempo_total_con)).format('HH:mm:ss')}</p>
											<p className="text-right">{usuario.vip > 0 ? 'VIP (Nivel ' + usuario.vip + ')' : 'Normal'}</p>
											<p className="text-right">{usuario.bandera_nombre}</p>
										</div>
									</div>
								</div>
							</div>
							<div className="card">
								<div className="card-header bg-primary text-white header-elements-inline">
									<h4 className="card-title">
										<span className="font-weight-semibold"><i className="icon-connection mr-2 icon-2x"></i> Estado del servidor</span>
									</h4>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<p><strong>Dirección:</strong></p>
											<p><strong><i className="icon-switch"></i> Online:</strong></p>
											<p><strong><i className="icon-info22"></i> Versión:</strong></p>
											<p><strong><i className="icon-bars-alt"></i> Avg. ping:</strong></p>
											<p><strong><i className="icon-station"></i> Avg. packet loss:</strong></p>
											<p><strong><i className="icon-power-cord"></i> Uptime:</strong></p>
											<p><strong><i className="icon-statistics"></i> Since:</strong></p>
										</div>
										<div className="col-md-6">
											<p className="text-right">comunidadgamer.ddns.net</p>
											<p className="text-right">{ts3_info.virtualserver_clientsonline + ' / ' + ts3_info.virtualserver_maxclients + ' (' + Math.round((ts3_info.virtualserver_clientsonline * 100) / ts3_info.virtualserver_maxclients) + '%)'}</p>
											<p className="text-right">{ts3_info.virtualserver_version}</p>
											<p className="text-right">{ts3_info.virtualserver_total_ping} ms</p>
											<p className="text-right">{ts3_info.virtualserver_total_packetloss_total} %</p>
											<p className="text-right">{ts3_info.virtualserver_uptime > 0 ? Math.trunc(Number(ts3_info.virtualserver_uptime) / (3600 * 24)) + 'd ' + Math.trunc(Number(ts3_info.virtualserver_uptime) % (3600 * 24) / 3600) + 'h ' + Math.trunc(Number(ts3_info.virtualserver_uptime) % 3600 / 60) + 'm' : null}</p>
											<p className="text-right">08/03/2017 14:32:45</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Inicio.propTypes = {
	auth: PropTypes.object.isRequired,
	getInfo: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	ts3: state.ts3
});

export default connect(
	mapStateToProps,
	{ getInfo }
)(Inicio);
