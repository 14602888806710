import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { URL_API } from '../config.js';

class LeftNavbar extends Component {
	static propTypes = {
		auth: PropTypes.object.isRequired
	};

	/* eslint-disable */
	render() {
		const { isAuthenticated, usuario } = this.props.auth;
		if (isAuthenticated == true) {
			return (
				<div className="sidebar sidebar-dark sidebar-main sidebar-expand-md">
					<div className="sidebar-mobile-toggler text-center">
						<a href="#" className="sidebar-mobile-main-toggle">
							<i className="icon-arrow-left8" />
						</a>
						Navigation
						<a href="#" className="sidebar-mobile-expand">
							<i className="icon-screen-full" />
							<i className="icon-screen-normal" />
						</a>
					</div>
					<div className="sidebar-content">
						<div className="sidebar-user">
							<div className="card-body">
								<div className="media">
									<div className="mr-3">
										<a href="#">
											<img src={URL_API + '/images/avatares_ts3/' + usuario.id + '.png'} width="38" height="38" className="rounded-circle" alt="" />
										</a>
									</div>
									<div className="media-body">
										<div className="media-title font-weight-semibold">{usuario.nombre}</div>
										<div className="font-size-xs opacity-50">
											<i className="icon-pin font-size-sm" /> &nbsp;{usuario.pais}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="card card-sidebar-mobile">
							<ul className="nav nav-sidebar" data-nav-type="accordion">
								<li className="nav-item-header">
									<div className="text-uppercase font-size-xs line-height-xs">Main</div> <i className="icon-menu" title="Main" />
								</li>
								<li className="nav-item">
									<NavLink to="/" exact className="nav-link" activeClassName="active"><i className="icon-home4" /><span>Inicio</span></NavLink>
								</li>
								<li className="nav-item">
									<NavLink to="/canales" className="nav-link"><i className="icon-list-unordered" /><span>Canales</span></NavLink>
								</li>
								<li className="nav-item">
									<NavLink to="/grupos" className="nav-link"><i className="icon-make-group" /><span>Grupos</span></NavLink>
								</li>
							</ul>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(
	mapStateToProps,
	null
)(LeftNavbar);
