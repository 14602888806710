import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { URL_API } from '../config.js';

import { logout } from '../actions/authActions';

class Navbar extends Component {
	/* eslint-disable */
	render() {
		const { isAuthenticated, usuario } = this.props.auth;
		if (isAuthenticated == true) {
			return (
				<div className="navbar navbar-expand-md navbar-dark">
					<div className="navbar-brand">
						<a href="../full/index-2.html" className="d-inline-block">
							Ra
						</a>
					</div>
					<div className="d-md-none">
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
							<i className="icon-tree5" />
						</button>
						<button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
							<i className="icon-paragraph-justify3" />
						</button>
					</div>
					<div className="collapse navbar-collapse" id="navbar-mobile">
						<ul className="navbar-nav">
							<li className="nav-item">
								<a className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
									<i className="icon-paragraph-justify3" />
								</a>
							</li>
						</ul>
						{usuario.online ? <span className="badge bg-success ml-md-3 mr-md-auto">Online</span> : ''}
						<ul className="navbar-nav ml-auto">
							<li className="nav-item dropdown dropdown-user">
								<a href="#" className="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown">
									<img src={URL_API + '/images/avatares_ts3/' + usuario.id + '.png'} className="rounded-circle mr-2" height="34" alt="" />
									<span>{usuario.nombre}</span>
								</a>
								<div className="dropdown-menu dropdown-menu-right">
									<NavLink to="/preferencias" exact className="dropdown-item" activeClassName="active">
										<i className="icon-user-plus" /> Preferencias
									</NavLink>
									<div className="dropdown-divider" />
									<a onClick={this.props.logout} className="dropdown-item">
										<i className="icon-switch2" /> Cerrar sesión
									</a>
								</div>
							</li>
						</ul>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

Navbar.propTypes = {
	auth: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(
	mapStateToProps,
	{ logout }
)(Navbar);
